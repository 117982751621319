<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import useProducts from "../../../hooks/useProducts";
import { onBeforeMount } from "vue";
import { useRoute } from "vue-router/auto";
import ProductLayout from "../../../layouts/ProductLayout.vue";
import { setPageTitle } from "../../../utils/pageTitle";
import { useThemeStore } from "@/stores/themeStore";
import Warehouses from "@/components/common/Warehouses.vue";
import { buildQueryParams } from "@/utils/agGridUtils";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);
const PRODUCT_EXTERNAL_FILTERS_KEY = "products-external-filter";

const { fetchProduct, fetchLots, product, lotItems } = useProducts();

const route = useRoute();
const themeStore = useThemeStore();

let gridApi;

onBeforeMount(async () => {
  await fetchProduct(route.params.id);
  setPageTitle(`Product -${product.value.id}`);
});

const onGridReady = async (params) => {
  gridApi = params.api;
  await fetchLots(route.params.id, buildQueryParams({}, PRODUCT_EXTERNAL_FILTERS_KEY));
  gridApi.setGridOption("rowData", lotItems.value);
};

const columnDefs = [
  { field: "product_sku", headerName: "SKU", cellDataType: "text" },
  { field: "product_name", headerName: "Item", cellDataType: "text" },
  { field: "warehouse_nickname", headerName: "Warehouse", cellDataType: "text" },
  { field: "lot", headerName: "Lot", cellDataType: "text" },
  { field: "expiry", headerName: "Expiry", cellDataType: "text" },
  { field: "pallet_count", headerName: "# of Pallets", cellDataType: "text" },
  { field: "case_qty", headerName: "Case Qty", cellDataType: "text" },
];

const defaultColDef = {
  sortable: true,
  suppressHeaderMenuButton: true,
  width: 120,
};

const gridOptions = {
  autoSizeStrategy: {
    type: "fitGridWidth",
    defaultMinWidth: 100,
  },
};

const warehouseFiltersChangedCallback = async () => {
  await fetchLots(route.params.id, buildQueryParams({}, PRODUCT_EXTERNAL_FILTERS_KEY));
};
</script>

<template>
  <div id="grid-wrapper">
    <Warehouses
      class="mb-6"
      :callBack="warehouseFiltersChangedCallback"
      :parent-filter="PRODUCT_EXTERNAL_FILTERS_KEY"
    />
    <ProductLayout :product="product">
      <div class="ml-4 mb-4">
        <h4 class="text-center">Lots for this SKU</h4>
      </div>
      <div class="lot-table d-flex">
        <AgGridVue
          style="width: 100%; height: 100%"
          :class="themeStore.agTheme"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
        >
        </AgGridVue>
      </div>
    </ProductLayout>
  </div>
</template>

<style scoped>
.lot-table {
  height: 600px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
